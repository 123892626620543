import React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import BlockQuote from '../components/BlockQuote'
import GridImage from '../components/GridImage'
import Sites from '../components/Sites'

function PrivacyPages() {
  return (
    <Layout>
      <Seo
        title={`Kebijakan Privasi Situs Tips Judi Online Indonesia Gubelinlab`}
        description={`Kebijakan Privasi Situs Tips Judi Online Indonesia Gubelinlab Yang Berlaku Untuk Semua Bettor Yang Menggunakan Layanan Kami`}
        url={`https://gubelinlab.com/kebijakan-privasi/`}
        image={`https://gubelinlab.com/images/og_image.jpg`}
      />
      <div className="bg-white overflow-hidden">
        <div className="relative max-w-8xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div className="hidden lg:block bg-gray-50 absolute top-0 bottom-0 h-3/4 left-3/4 w-screen" />
          <div className="mx-auto text-base max-w-prose lg:max-w-none">
            <h1 className="text-base text-rose-600 font-semibold tracking-wide uppercase">
              Kebijakan Privasi
            </h1>
            <h2 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Situs Tips Judi Online Indonesia Gubelinlab
            </h2>
          </div>
          <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
            <div className="relative lg:row-start-1 lg:col-start-2">
              <svg
                className="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20"
                width="404"
                height="384"
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect width="404" height="384" fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
              </svg>
              <div className="relative text-base mx-auto max-w-prose lg:max-w-none space-y-8">
                <GridImage />
                <BlockQuote
                  htmlText={`Kebijakan Privasi Situs Tips Judi Online Indonesia Gubelinlab Yang Berlaku Untuk Semua Bettor Yang Menggunakan Layanan Kami`}
                />
              </div>
            </div>
            <div className="mt-8 lg:mt-0">
              <div className="text-base max-w-prose mx-auto lg:max-w-none">
                <p className="text-lg text-gray-500">
                  Di Gubelinlab, dapat diakses dari https://gubelinlab.com, salah satu prioritas
                  utama kami adalah privasi pengunjung kami. Dokumen Kebijakan Privasi ini berisi
                  jenis informasi yang dikumpulkan dan dicatat oleh Gubelinlab dan bagaimana kami
                  menggunakannya.
                </p>
              </div>
              <div className="mt-5 prose prose-rose prose-lg text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
                <p>
                  Jika Anda memiliki pertanyaan tambahan atau memerlukan informasi lebih lanjut
                  tentang Kebijakan Privasi kami, jangan ragu untuk menghubungi kami.
                </p>
                <h2>File Log</h2>
                <p>
                  Gubelinlab mengikuti prosedur standar menggunakan berkas log. File-file ini
                  mencatat pengunjung ketika mereka mengunjungi situs web. Semua perusahaan hosting
                  melakukan ini dan merupakan bagian dari analitik layanan hosting. Informasi yang
                  dikumpulkan oleh file log termasuk alamat protokol internet (IP), jenis browser,
                  Penyedia Layanan Internet (ISP), cap tanggal dan waktu, halaman rujukan / keluar,
                  dan mungkin jumlah klik. Ini tidak terkait dengan informasi apa pun yang dapat
                  diidentifikasi secara pribadi. Tujuan dari informasi tersebut adalah untuk
                  menganalisis tren, mengelola situs, melacak pergerakan pengguna di situs web, dan
                  mengumpulkan informasi demografis.
                </p>
                <h2>Cookies dan Web Beacon</h2>
                <p>
                  Seperti situs web lainnya, Gubelinlab menggunakan 'cookie'. Cookies ini digunakan
                  untuk menyimpan informasi termasuk preferensi pengunjung, dan halaman-halaman di
                  situs web yang diakses atau dikunjungi pengunjung. Informasi tersebut digunakan
                  untuk mengoptimalkan pengalaman pengguna dengan menyesuaikan konten halaman web
                  kami berdasarkan jenis browser pengunjung dan / atau informasi lainnya.
                </p>
                <p>
                  Untuk informasi lebih umum tentang cookie, harap baca artikel "What Are Cookies"
                  di situs web Izin Cookie.
                </p>
                <h2>Kebijakan Privasi</h2>
                <p>
                  Anda dapat melihat daftar ini untuk menemukan Kebijakan Privasi masing-masing
                  mitra periklanan Gubelinlab.
                </p>
                <p>
                  Server iklan atau jaringan iklan pihak ketiga menggunakan teknologi seperti cookie
                  , JavaScript, atau Web Beacon yang digunakan di masing-masing iklan dan tautan
                  yang muncul di Gubelinlab, yang dikirim langsung ke browser pengguna. Mereka
                  secara otomatis menerima alamat IP Anda saat ini terjadi. Teknologi ini digunakan
                  untuk mengukur keefektifan kampanye iklan mereka dan / atau untuk mempersonalisasi
                  konten iklan yang Anda lihat di situs web yang Anda kunjungi.
                </p>
                <p>
                  Perhatikan bahwa Gubelinlab tidak memiliki akses ke atau kontrol atas cookie yang
                  digunakan oleh pengiklan pihak ketiga.
                </p>
                <h2>Kebijakan Privasi Pihak Ketiga</h2>
                <p>
                  Kebijakan Privasi Gubelinlab tidak berlaku untuk pengiklan atau situs web lain.
                  Karena itu, kami menyarankan Anda untuk berkonsultasi dengan masing-masing
                  Kebijakan Privasi dari server iklan pihak ketiga ini untuk informasi yang lebih
                  rinci. Ini mungkin termasuk praktik dan instruksi mereka tentang cara menyisih
                  dari opsi tertentu.
                </p>
                <p>
                  Anda dapat memilih untuk menonaktifkan cookie melalui opsi peramban individual
                  Anda. Untuk mengetahui informasi lebih rinci tentang manajemen cookie dengan
                  browser web tertentu, dapat ditemukan di situs web masing-masing browser. Apa Itu
                  Cookies?
                </p>
                <h2>Informasi Anak-Anak</h2>
                <p>
                  Bagian lain dari prioritas kami adalah menambahkan perlindungan untuk anak-anak
                  saat menggunakan internet. Kami mendorong orang tua dan wali untuk mengamati,
                  berpartisipasi dalam, dan / atau memantau dan membimbing aktivitas online mereka.
                </p>
                <p>
                  Gubelinlab tidak dengan sengaja mengumpulkan Informasi Identitas Pribadi apa pun
                  dari anak-anak di bawah usia 13 tahun. Jika menurut Anda Anak memberikan informasi
                  semacam ini di situs web kami, kami sangat menganjurkan Anda untuk segera
                  menghubungi kami dan kami akan melakukan upaya terbaik kami untuk segera menghapus
                  informasi tersebut dari catatan kami.
                </p>
                <h2>Hanya Kebijakan Privasi Online</h2>
                <p>
                  Kebijakan Privasi ini hanya berlaku untuk aktivitas online kami dan berlaku untuk
                  pengunjung situs web kami sehubungan dengan informasi yang mereka bagikan dan /
                  atau kumpulkan di Gubelinlab. Kebijakan ini tidak berlaku untuk informasi apa pun
                  yang dikumpulkan secara offline atau melalui saluran selain situs web ini.
                </p>
                <h2>Persetujuan</h2>
                <p>
                  Dengan menggunakan situs web kami, Anda dengan ini menyetujui Kebijakan Privasi
                  kami dan menyetujui Ketentuannya dan Ketentuan.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Sites />
    </Layout>
  )
}

export default PrivacyPages
